import quotesType from '../../State/quotesType'
export const alert = (severity, summary, detail) => window.growl.show({ severity, summary, detail })
export const services = quotesType
/**
 * Questa funzione prepara gli stati finali basati su un JSON e un servizio specifico.
 * Viene trovata la configurazione del servizio corrispondente all'ID fornito.
 * Per ogni filtro presente nella configurazione del servizio, viene creato uno stato finale
 * che include il nome del filtro e i dati del JSON.
 *
 * @param {Object} json - I dati JSON da includere negli stati finali.
 * @param {string} service - L'ID del servizio per cui preparare gli stati.
 * @returns {Array} Un array di stati finali preparati.
 * @author @mranocchia
 */
export const prepareStates = (json, service) => {
  const s = quotesType.find(serv => serv.id === service)
  const finalState = []
  s.filters.forEach((filter) => {
    finalState.push({ type: filter.name, ...json })
  })
  return finalState
}
