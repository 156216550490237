import React, { useEffect, useState, useRef } from 'react'
import { Button, Flex, Spinner, Text } from '../../../Components'
import { FC, useCQuery } from '../../../Services'
import { getCorrectState, getStateDefaultData, patchTraveller, updatePhonePrefix } from './Functions'
import { useParams, useNavigate } from 'react-router-dom'
import { getStateValues } from '../GigiList/Functions'
import { MainDataSection, SenderSection } from './Sections'
import { FormHeader } from './Sections/FormHeader'
import { FormChat } from './FormChat'
import { Rooms } from './Sections/Rooms'
import { getRoomsCount, getTotalChildrenCount } from './Sections/Functions'
import { invalidateQuery } from '../../../Services/QueryCache'

const { RUNNING, READYTORESTART } = getStateValues()

export function Form () {
  const { conversationId } = useParams()
  const navigate = useNavigate()
  const { data: conversation = {}, isSuccess } = useCQuery(['conversation', conversationId])
  const { isSuccess: isSuccessFormFields } = useCQuery(['formFields'])
  const stateRef = useRef()
  const [readOnly, setReadOnly] = useState(false)
  const [render, setRender] = useState(false)
  const [lastProcessStepId, setLastProcessStepId] = useState()
  const [lastStatus, setLastStatus] = useState()
  const [sender, setSender] = useState({})
  const [cellulare, setCellulare] = useState('')
  const [prefix, setPrefix] = useState('')
  const [userEdit, setUserEdit] = useState(false)

  // funzione che cambia user edit a true se l'utente modifica un campo
  // se userEdit è true non viene sovrascritto lo stato con i dati della conversazione
  const changeUserEdit = (set) => (...data) => {
    setUserEdit(true)
    set(...data)
  }

  const setState = (newState, forceRender) => {
    const mustRender = [
      getRoomsCount(newState) !== getRoomsCount(stateRef.current),
      getTotalChildrenCount(newState) !== getTotalChildrenCount(stateRef.current),
      forceRender
    ]?.some((v) => v)
    stateRef.current = newState
    mustRender && setRender(!render)
  }

  useEffect(() => {
    if (isSuccess) {
      const lastProcess = conversation?.lastProcess
      const lastStatus = lastProcess?.state
      setLastStatus(lastStatus)
      const lastProcessStep = conversation?.lastProcessStep
      const lastPayload = Object.keys(lastProcessStep?.finalState || {}).length ? lastProcessStep?.finalState : lastProcessStep?.initialState || {}
      // posso editare il form nel caso in cui il processo sia in pausa (quindi senza errori)
      // oppure se il processo è in errore / redirect operator e sono ancora in fase di raccolta dati

      const editable = (lastStatus !== RUNNING && lastStatus !== READYTORESTART)
      const newReadOnly = !editable

      const hasDifferentReadOnly = newReadOnly !== readOnly
      const isStateUninitialized = !stateRef.current
      const hasLastProcessStepIdChanged = lastProcessStepId !== lastProcessStep?.id
      const shouldForceUpdate = lastPayload?.forceUpdate

      const shouldUpdateSettings = hasDifferentReadOnly || isStateUninitialized || hasLastProcessStepIdChanged || shouldForceUpdate

      if (userEdit) return

      if (shouldUpdateSettings) {
        setLastProcessStepId(lastProcessStep?.id)
        setReadOnly(newReadOnly)
        setState(getStateDefaultData(lastPayload), true)
        updatePhonePrefix(conversation, setCellulare, setPrefix)
      }
    }
  }, [isSuccess, conversation])

  if (!isSuccess || !stateRef.current) return <Spinner />

  /**
   * Aggiorna lo stato asincronamente e gestisce le operazioni correlate al mittente e al viaggiatore.
   *
   * Il metodo prepara il nuovo stato adottando quello corrente e aggiungendo il flag {@code sentFromGigiForm}.
   * Poi, compila il numero di cellulare completo e aggiorna i dati del mittente. Effettua una chiamata di servizio
   * per aggiornare lo stato con l'ID della conversazione corrente. Esegue un controllo sull'input del cliente;
   * se il cliente non ha un ID, mostra un modale. Successivamente, tenta di patchare i dati del viaggiatore
   * e notifica l'utente del successo tramite un messaggio. Se si verifica un errore durante il processo,
   * notifica l'utente dell'errore.
   */
  const updateState = async () => {
    try {
      const correctState = { ...getCorrectState(stateRef.current), sentFromGigiForm: true }
      const cellulareCompleto = `${prefix}${cellulare}`
      sender.phone = cellulareCompleto
      await FC.service('info').create({ action: 'updateState', state: correctState, conversationId })
      await patchTraveller(conversation, sender)
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Salvataggio effettuato con successo' })
      return true
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio' })
      return false
    }
  }

  /**
   * Conclude la conversazione attuale.
   * Questa funzione verifica prima l'input dell'utente e poi controlla se l'id del cliente non è settato.
   * Se le condizioni sono soddisfatte, procede a impostare lo stato della conversazione su terminata,
   * crea un nuovo processo e invalida la cache delle conversazioni.
   * In caso di successo, mostra un messaggio positivo all'utente. In caso di errore, viene mostrato un messaggio di errore.
   *
   * @async
   * @function endConversation
   * @returns {Promise<void>} Ritorna una promise che si risolve quando la conversazione è stata conclusa con successo o rifiutata con errore.
   */
  const endConversation = async () => {
    try {
      await FC.service('conversations').patch(conversationId, { status: 'ended' })
      invalidateQuery(['conversation', 'conversations'])
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Conversazione conclusa con successo' })
    } catch (e) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore conclusione conversazione' })
    }
  }

  const stateProps = { state: [stateRef.current, setState], readOnly }

  if (!stateRef.current || !isSuccessFormFields) return <Spinner />
  return (
    <Flex fw as row>
      <Flex style={{ width: '60%' }} id='gigiForm'>
        <Flex fw jb style={{ height: 30 }} row>
          <Flex row>
            {lastStatus === RUNNING &&
              <>
                <Text value='Elaborazione messaggio in corso' style={{ fontWeight: 'bold', width: 270 }} />
                <Spinner size={20} style={{ marginLeft: 10, marginTop: 1 }} />
              </>}
          </Flex>
        </Flex>
        <SenderSection
          sender={[sender, changeUserEdit(setSender)]}
          prefix={[prefix, setPrefix]} cellulare={[cellulare, setCellulare]} readOnly={readOnly}
        />
        <MainDataSection {...stateProps} />
        <Rooms {...stateProps} />
        <Flex row style={{ marginTop: 20 }}>
          <Button icon='fa-duotone fa-arrow-left' label='Torna indietro' onClick={() => navigate('/operatore/list')} style={{ marginRight: 20 }} />
          <Button icon='fa-duotone fa-floppy-disk' label='Salva' onClick={updateState} disabled={readOnly} />
          {conversation?.status !== 'ended' && conversation?.status !== 'archived' &&
            <Button icon='fa-duotone fa-xmark' label='Concludi' onClick={endConversation} disabled={readOnly} style={{ marginLeft: 20 }} />}
        </Flex>
      </Flex>
      <Flex style={{ width: '39%', marginLeft: '1%', position: 'sticky' }}>
        <FormHeader conversationId={conversationId} />
        <FormChat stateRef={stateRef} readOnly={readOnly} />
      </Flex>
    </Flex>
  )
}
