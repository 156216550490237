import { FC } from '../../../Services'

/**
 * Ricerca duplicati per un'agenzia specifica basandosi sul codice cliente Nerone.
 *
 * @async
 * @param {Object} agency - L'oggetto rappresentante l'agenzia da controllare.
 * @returns {Promise<string>} Una promessa che si risolve con una stringa indicando il risultato del controllo:
 *         'merge' se esiste un duplicato con lo stesso nome dell'agenzia, 'inconsistent' se esistono duplicati con nomi diversi,
 *          o 'ok' se non sono stati trovati duplicati.
 * @description Questa funzione asincrona ricerca duplicati all'interno del servizio ambientale utilizzando il codClienteNerone.
 *              I duplicati vengono determinati confrontando il codClienteNerone e l'id dell'agenzia in questione.
 *              La funzione ritorna uno dei tre possibili stati a seconda dei risultati della ricerca:
 *              'merge' indica la presenza di un duplicato con lo stesso nome dell'agenzia e suggerisce una fusione dei dati,
 *              'inconsistent' indica la presenza di un duplicato con un nome diverso (inconsistenza),
 *              e 'ok' indica che non ci sono duplicati.
 */
export const searchForDuplicates = async (agency) => {
  const value = agency.codClienteNerone
  const normalizedString = value.replace(/\s/g, ' ')

  const results = await FC.service('environments').find({
    query: {
      codClienteNerone: normalizedString,
      id: { $ne: agency.id }
    }
  })

  if (results.length > 0) {
    if (results[0].agencyName === agency.agencyName) {
      return 'merge'
    }
    return 'inconsistent'
  }

  return 'ok'
}
