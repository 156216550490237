import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, Spinner, Text, DateSelect } from '../../../Components'
import { Column } from 'primereact/column'
import { FC, invalidateQuery, useCInfiniteQuery } from '../../../Services'
import { cap } from '../../../State'
import { useCapsule } from '../../../State/Functions'
import { columnProps } from '../../../Services/Functions'

const patchConversation = async (id) => {
  try {
    const res = await FC.service('conversations').patch(id, { status: 'ended' })
    invalidateQuery(['conversation', 'conversations'])
    window.growl.show({ severity: 'success', detail: 'Conversazione chiusa' })
    return res
  } catch (e) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore chiusura conversazione' })
    return false
  }
}

export function ConversationsList () {
  const [selectedDate, setSelectedDate] = useCapsule(cap.selectedDateConversationList)
  const { data: conversations = [], isSuccess } = useCInfiniteQuery(['conversations', selectedDate])
  const [conversationsTable, setConversationsTable] = useState([])
  const navigate = useNavigate([])

  useEffect(() => {
    isSuccess && setConversationsTable(conversations.map((r) => ({ ...r, createdAt: new Date(r.createdAt).toLocaleString() })))
  }, [JSON.stringify(conversations), isSuccess])

  const BtnActionProc = ({ id }) => (
    <Flex width={40} jb row>
      <Button round icon='fa-duotone fa-eye' onClick={() => navigate('/conversations/' + id)} />
    </Flex>
  )

  const BtnActionClose = ({ id, status }) => (
    <Flex width={40} jb row>
      {status === 'open' && <Button round icon='fa-duotone fa-xmark' onClick={() => patchConversation(id)} />}
    </Flex>
  )

  if (!isSuccess) return <Spinner />

  return (
    <Flex fw fh js>
      <Text value='Lista Conversazioni' center size={20} bold />
      <Button icon='fa-duotone fa-arrow-left' label='Torna indietro' style={{ marginTop: 30 }} onClick={() => navigate('/process/dashboard')} />
      <Flex fw row style={{ marginTop: 30 }}>
        <DateSelect date={[selectedDate, setSelectedDate]} />
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={conversationsTable} emptyMessage='Nessun processo trovato' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='id' {...columnProps('Id')} />
        <Column field='travellerId' {...columnProps('Viaggiatore')} body={({ traveller: { email = '', phone = '' } = {} }) => [email, phone]?.filter((e) => e).join(' - ')} />
        <Column field='status' {...columnProps('Stato')} />
        <Column field='createdAt' {...columnProps('Data creazione')} />
        <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionProc {...props} />} />
        <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionClose {...props} />} />
      </DataTable>
    </Flex>
  )
}
