import { useLocation } from 'react-router-dom'
// import giginoPaneVino from '../Resources/giginoPaneVino.png'
const pathMap = [
  { path: '/login', label: 'Login' },
  { path: '/operatore/list', label: 'Conversazioni' },
  { path: '/users', label: 'Utenti' }
]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'Home'

  const matchTitle = ({ path, include }) => include ? window.location.pathname.includes(path) : path === window.location.pathname

  return pathMap.find(matchTitle)?.label || 'Home'
}

export const useMenuItems = ({ role }) => ([
  { label: 'Chat', icon: 'fa-duotone fa-paper-plane-top', path: '/chat/', role: ['admin'] },
  { label: 'Elenco Agenzie', icon: 'fa-duotone fa-briefcase', path: '/admin/agency/list', role: ['admin'] },
  { label: 'Elenco Utenti', icon: 'fa-duotone fa-users', path: '/admin/users/list', role: ['admin'] },
  { label: 'Elenco Viaggiatori', icon: 'fa-duotone fa-suitcase-rolling', path: '/admin/travellers/list', role: ['admin'] },
  { label: 'line', role: ['admin', 'operatore'] },
  { label: 'Processi', icon: 'fa-duotone fa-arrow-progress', path: '/process/dashboard', role: ['admin'] },
  { label: 'line', role: ['admin', 'operatore'] },
  { label: 'Conversazioni', icon: 'fa-duotone fa-phone-rotary', path: '/operatore', role: ['admin', 'operatore'] },
  { label: 'line', role: ['admin'] },
  { label: 'Admin Coefficienti', path: '/admin/coefficient', icon: 'fa-duotone fa-solid fa-sliders', role: ['admin'] },
  { label: 'line', role: ['admin'] },
  { label: 'Logout', icon: 'fa-duotone fa-power-off', path: 'logout' }
]).filter(item => !item.role || item?.role?.includes(role))
