import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Spinner } from '../../Components'
import ServiceSections from './Components/ServiceSections'
import Header from './Components/Header'
import { FC, invalidateQuery, useCQuery } from '../../Services'
import { alert } from './Functions'

export const AdminCoefficient = () => {
  const { data: adminCoefficient = [], isSuccess } = useCQuery(['adminCoefficient'])
  const [planeCoefficient, setPlaneCoefficient] = useState([])
  const [hotelCoefficient, setHotelCoefficient] = useState([])
  useEffect(() => {
    const { planeCoefficient, hotelCoefficient } = adminCoefficient
    setPlaneCoefficient(planeCoefficient)
    setHotelCoefficient(hotelCoefficient)
  }, [adminCoefficient])

  if (!isSuccess) return <Spinner />

  /**
 * Questa funzione salva i coefficienti aggiornati per aerei e hotel nel servizio 'adminCoefficient'.
 * Utilizza una chiamata asincrona per eseguire l'operazione di patch con i nuovi dati.
 * Dopo il salvataggio, viene mostrato un messaggio di successo all'utente e viene invalidata la query
 * relativa ai coefficienti amministrativi per garantire che i dati siano aggiornati.
 *
 * @returns {Promise<void>} Una promessa che si risolve quando l'operazione è completata.
 * @author @mranocchia
 */
  const save = async () => {
    await FC.service('adminCoefficient').patch(1, { planeCoefficient, hotelCoefficient })
    alert('success', 'Salvataggio effettuato', '')
    invalidateQuery(['adminCoefficient'])
  }

  return (
    <Flex>
      <Card style={{ width: '80%' }}>
        <Header />
        <ServiceSections
          plane={{ coefficient: [planeCoefficient, setPlaneCoefficient] }}
          hotel={{ coefficient: [hotelCoefficient, setHotelCoefficient] }}
        />
        <Flex fw>
          <Button label='Salva' style={{ width: 120, marginTop: 20 }} onClick={save} />
        </Flex>
      </Card>
    </Flex>
  )
}
