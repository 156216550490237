import React from 'react'
import { Flex, PanelText } from '../../../Components'
import { InputNumber } from 'primereact/inputnumber'

export const QuotesTypeElement = ({ coefficient: [coefficient = [], setCoefficient], filter: { name, code } }) => (
  <Flex js as wrap style={{ gap: 20 }} fw>
    <Flex as fh>
      <PanelText value={'Tipo Preventivo ' + name + ':'} header />
      <PanelText value={'Imposta coefficienti per ' + name + ':'} />
    </Flex>
    <Flex js as fw row>
      <Flex js as row wrap style={{ width: '100%', gap: 25, marginLeft: 25 }}>
        {Object.keys(coefficient.find(obj => obj.type === name))?.map((key) => {
          if (key === 'type') return null
          return (
            <Flex key={key} row>
              <Flex as js wrap style={{ gap: 10 }}>
                <PanelText value={key} style={{ fontSize: 20 }} />
                <Flex row as js wrap style={{ gap: 10 }}>
                  {coefficient.find(obj => obj.type === name)[key]?.map((coeff, index) => (
                    <Flex key={index} js as className='inputNumbers'>
                      <PanelText value={coeff.name} />
                      <InputNumber
                        className='inputNumbers'
                        placeholder={coeff.name}
                        value={coeff.value}
                        onValueChange={(e) =>
                          setCoefficient(prev => {
                            const newCoefficient = prev
                            newCoefficient.find(obj => obj.type === name)[key][index].value = e.value
                            return newCoefficient
                          })}
                        mode='decimal'
                        showButtons
                        min={0}
                        max={50}
                        step={coeff.code === 'numQuotes' ? 1 : 0.01}
                        style={{ marginTop: 5 }}
                      />
                    </Flex>))}
                </Flex>
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  </Flex>
)
