import { Card, ChatMessages, Flex, Writer, Icon, Text } from '../../../Components'
import { useCQuery } from '../../../Services'
import { useAuth } from '../../../Provider/AuthProvider'
import { createNewProcess, getCorrectState, getSenderLabel, markMessagesAsRead } from './Functions'
import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'

const scrollToBottom = (element, forceScroll = false) => {
  // scroll to bottom only if the user is not scrolling up
  if (element?.current?.scrollHeight - element?.current?.scrollTop === element?.current?.clientHeight || forceScroll) {
    setTimeout(() => element?.current?.scrollTo({ top: element.current.scrollHeight, behavior: 'smooth' }), 100)
  }
}

export const FormChat = ({ stateRef, readOnly }) => {
  const { data: users = [], isSuccess: isSuccessUsers } = useCQuery(['users'])
  const { conversationId } = useParams()
  const { data: conversation = {}, isSuccess } = useCQuery(['conversation', conversationId])
  const [messages, setMessages] = useState([])
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  const chatContainer = useRef()
  const [newMessagesCounter, setNewMessagesCounter] = useState(0)

  useEffect(() => {
    const onScroll = () => {
      if (chatContainer?.current?.scrollHeight - chatContainer?.current?.scrollTop === chatContainer?.current?.clientHeight) {
        setNewMessagesCounter(0)
      }
    }
    chatContainer?.current?.addEventListener('scroll', onScroll)
    return () => chatContainer?.current?.removeEventListener('scroll', onScroll)
  }, [chatContainer.current])

  useEffect(() => {
    if ((messages?.length < conversation?.messages?.length ||
      messages.map(m => m.attachments?.length) < conversation?.messages?.map(m => m.attachments?.length)) && isSuccess && isSuccessUsers) {
      const travellerPhone = conversation?.traveller?.phone
      setMessages(conversation?.messages?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map(({ message: messageText, sender, createdAt, attachments, userId }) => ({
          message: messageText,
          sender,
          user: getSenderLabel(sender, travellerPhone, users.find(u => u.id === userId)),
          createdAt: new Date(createdAt),
          attachments
        })))
      markMessagesAsRead(conversation)
      if (chatContainer?.current && chatContainer?.current?.scrollHeight - chatContainer?.current?.scrollTop !== chatContainer?.current?.clientHeight) {
        setNewMessagesCounter(newMessagesCounter + 1)
      }
      scrollToBottom(chatContainer)
    }
  }, [conversation, isSuccess, isSuccessUsers])

  const sendMessage = async (message, attachments) => {
    setMessages([...messages, { message, user: getSenderLabel('operator', conversation?.traveller?.phone, user), sender: 'operator', createdAt: new Date() }])
    scrollToBottom(chatContainer, true)
    const newState = { ...getCorrectState(stateRef.current), operatorInfo: { messageToSend: message, userId: user.id }, attachments }
    await createNewProcess(newState, conversationId, 'sendOperatorMessageFromFormSingleUnit')
  }

  if (!isSuccess) return null
  return (
    <Flex fw>
      <Card style={{ padding: 10, borderRadius: 10, marginTop: 10, width: '100%', position: 'relative' }}>
        <Flex
          onClick={() => { setNewMessagesCounter(0); scrollToBottom(chatContainer, true) }} row jb className='btn'
          style={{ position: 'absolute', bottom: 70, right: 15, padding: 5, zIndex: 1000, width: 40, height: 30, borderRadius: 15, textAlign: 'center', cursor: 'pointer', visibility: newMessagesCounter > 0 ? 'visible' : 'hidden' }}
        >
          <Icon name='fa-duotone fa-arrow-down' size={20} />
          <Text value={newMessagesCounter} />
        </Flex>
        <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }} ref={chatContainer}>
          <ChatMessages messages={messages} jsonVisualized={false} />
        </div>
        {conversation?.status !== 'archived' &&
          <Writer sendMessage={sendMessage} composerStyle={{ width: '100%', padding: 0 }} disabled />}
      </Card>
    </Flex>
  )
}
