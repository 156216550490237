import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { prefetchQuery, useCInfiniteQuery } from '../../../Services/QueryCache'
import { useAuth } from '../../../Provider/AuthProvider'
import { Button, Flex, Spinner, Text } from '../../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { conversationsStatus, formatDateHour, renderPhone } from './Functions'
import { columnProps } from '../../../Services/Functions'
import { Input } from '../../../Components/Common/Input'
import { useCapsule } from '../../../State/Functions'
import { cap } from '../../../State'

const getStatusFilterOptions = (role) => [
  { label: 'In corso', value: 'open' },
  { label: 'Concluse', value: 'ended', color: '#f19f16' },
  { label: 'Tutte le conversazioni', value: 'all' },
  { label: 'Conversazioni in errore', value: 'error' }
]

export const gattinoniId = 32

export function GigiList () {
  const [selectedFilter, setSelectedFilter] = useCapsule(cap.selectedFilter)
  const { data: conversations = [], isSuccess, isStale } = useCInfiniteQuery(['conversations', selectedFilter])
  const [conversationsTable, setConversationsTable] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const { user: { user } } = auth || {}

  const rowClass = ({ messagesToRead }) => `${messagesToRead > 0 ? 'unread-row' : 'read-row'}`

  useEffect(() => {
    if (isSuccess && !isStale) {
      // controllo se ci sono messaggi non letti, se sì setto il filtro su 'unread' altrimenti su 'all'
      // uso isStale per calcolare i messaggi non letti solo quando i dati della query sono aggiornati (a seguito di un invalidateQuery)
      setConversationsTable(conversations
        .map((r) => {
          const createdAt = new Date(r.createdAt)
          return {
            ...r,
            traveller: r.traveller || {},
            createdAt: formatDateHour(createdAt),
            statusLabel: r?.lastProcessState === 5 ? 'Errore' : conversationsStatus?.[r.status],
            title: r.title || 'Non definito',
            travellerId: r.traveller?.phone ? renderPhone(r) : 'Non definito',
            lastProcessState: r?.lastProcessState
          }
        })
      )
    }
  }, [JSON.stringify(conversations), location.pathname, selectedFilter, isSuccess])

  const BtnActionProc = ({ messagesToRead, statusLabel, id }) => (
    <Flex jb row>
      {(statusLabel === 'Errore') && <Text value='⚠️' />}
      <Button round icon='fa-duotone fa-paper-plane' style={{ marginRight: 10 }} onClick={() => navigate('/operatore/' + id)} onHover={() => prefetchQuery(['conversation', id?.toString()])} />
      {(messagesToRead > 0) &&
        <Flex style={{ marginRight: 10, color: 'white', backgroundColor: 'rgb(5, 202, 15)', borderRadius: '50%', width: 30, height: 30 }}>
          <Text bold center value={messagesToRead} style={{ flexGrow: 0, textAlign: 'center', width: '100%' }} />
        </Flex>}
    </Flex>
  )

  /**
 * Restituisce lo stile dell'etichetta di stato per una riga di dati.
 *
 * Questa funzione prende i dati di una riga e determina lo stile appropriato
 * per l'etichetta di stato in base al ruolo dell'utente e alle opzioni di filtro dello stato.
 *
 * @param {Object} rowData - I dati della riga che contengono lo stato e l'etichetta di stato.
 * @returns {JSX.Element} L'elemento Text con lo stile applicato all'etichetta di stato.
 *
 * @author @mranocchia
 */
  const getStatusLabelStyle = (rowData) => {
    const status = rowData.status
    const listStatus = getStatusFilterOptions(user?.role)
    const statusObj = listStatus.find((s) => s.value === status)
    const defaultStyle = { textAlign: 'center', border: 'none' }
    const style = statusObj?.color ? { ...defaultStyle, color: statusObj?.color } : defaultStyle
    return (<Text value={rowData.statusLabel} style={style} />)
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw>
      <Flex fw jb row wrap style={{ gap: 30 }}>
        <Flex row je style={{ marginTop: 10 }}>
          <span className='p-float-label'>
            <Input
              id='statusFilter' dropdown placeholder='Filtra per stato'
              options={getStatusFilterOptions(user?.role)}
              value={selectedFilter}
              onChange={({ statusFilter }) => setSelectedFilter(statusFilter)}
              style={{ width: 280, marginLeft: 5 }}
            />
            <label style={{ fontFamily: 'Lexend' }}>Stato conversazione</label>
          </span>
        </Flex>
      </Flex>
      <DataTable
        style={{ width: '100%', marginTop: 20 }} filterDisplay='row' className='TableSignatures'
        value={conversationsTable} emptyMessage='Nessuna conversazione trovata' responsiveLayout='scroll' paginator rows={10}
        rowClassName={rowClass}
      >
        <Column field='traveller.phone' {...columnProps('Cellulare')} />
        <Column field='traveller.email' {...columnProps('Email')} />
        <Column field='statusLabel' body={getStatusLabelStyle} {...columnProps('Stato')} />
        <Column field='createdAt' {...columnProps('Data inizio attività')} />
        <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionProc {...props} />} />
      </DataTable>
    </Flex>
  )
}
