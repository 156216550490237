import React from 'react'
import { Flex, Icon, PanelText } from '../../../Components'
import { Divider } from 'primereact/divider'

const Header = () => {
  return (
    <Flex fw>
      <Flex row js as fw wrap>
        <div
          style={{
            width: 53,
            height: 48,
            marginRight: 10,
            borderRadius: 10,
            border: '1px solid #E4E7EC',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Icon name='fa-duotone fa-solid fa-gear fa-spin' size={26} />
        </div>
        <Flex as fh>
          <PanelText value='Impostazione coefficienti ricerca' header />
          <PanelText value='Gestisci Nora AI' />
        </Flex>
      </Flex>
      <Divider />
    </Flex>
  )
}

export default Header
