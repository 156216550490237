import { useState } from 'react'
import { Flex } from '../Common/Flex'
import { Text } from '../Common/Text'
import { Composer } from './Composer'
import { Button } from '../Common/Button'
import { Card } from '../Common/Card'
import { JsonViewer } from '../Common/JsonViewer'
import { Modal } from '../Common/Modal'
const dateOptionsShort = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }

const ChatWrapper = ({ children }) => (
  <Card style={{ width: '100%' }}>
    <Flex fw style={{ justifyContent: 'space-between', height: '95%' }}>
      {children}
    </Flex>
  </Card>
)

const Writer = ({ sendMessage, composerStyle, disabled }) => {
  const [currentMessage, setCurrentMessage] = useState('')
  const [sending, setSending] = useState(false)

  const onSend = async (attachments) => {
    setSending(true)
    sendMessage(currentMessage, attachments)
    setSending(false)
  }

  return (
    <Flex row fw>
      <Composer update={setCurrentMessage} send={onSend} currentMessage={currentMessage} sending={sending} composerStyle={composerStyle} disabled={disabled} />
    </Flex>
  )
}

const ChatMessages = ({ messages, jsonVisualized }) => (
  <Flex fw js style={{ width: '100%', overflowY: 'auto', maxHeight: '90%', marginBottom: 20 }}>
    {messages.map((message, index) => (<ChatMessage key={index} message={message} jsonVisualized={jsonVisualized} />))}
  </Flex>
)

const ChatMessage = ({ message, jsonVisualized = true }) => {
  const { jsonPayload, message: messageText = '', user, createdAt, sender } = message
  const [showImg, setShowImg] = useState(false)
  const [media, setMedia] = useState(null)

  const decodedMessage = decodeURI(messageText)
  const isTravellerMessage = sender === 'traveller'
  return (
    <Flex fw ae={isTravellerMessage} as={!isTravellerMessage}>
      <div style={{
        backgroundColor: isTravellerMessage ? 'rgba(0,0,0, 0.2)' : 'rgba(255, 255, 255, 0.05)',
        borderRadius: 10,
        width: '95%',
        padding: 10,
        marginTop: 15,
        border: '1px solid #dadada',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
      }}
      >
        <Flex fw as row jb>
          <Text value={user} style={{ fontSize: 12 }} />
          <Text value={createdAt?.toLocaleString('it-IT', dateOptionsShort)} style={{ fontSize: 12 }} />
        </Flex>
        <hr style={{ width: '100%', margin: '5px 0' }} />
        {decodedMessage.match(/<\/?[^>]+(>|$)/g)
          ? decodedMessage?.replace(/<\/?[^>]+(>|$)/g, '')
            ?.replace(/&nbsp;/g, ' ')
            ?.split('\n')
            ?.map((p) => p?.trim())
            ?.filter(Boolean)
            ?.map((p = '\n', index) => (<Text key={index} value={p} size={15} style={{ wordBreak: 'break-word' }} />))
          : <Text value={decodedMessage} size={15} style={{ wordBreak: 'break-word' }} />}
        <Flex row style={{ marginTop: 10 }}>
          {message.attachments?.filter(({ type }) => type.includes('image')).map((mediaMessage, index) => (
            <img key={index} src={mediaMessage?.url} style={{ maxWidth: '100%', maxHeight: '45vh', cursor: 'pointer' }} onClick={() => { setMedia(mediaMessage); setShowImg(true) }} />
          ))}
          {message.attachments?.filter(({ type }) => type.includes('audio')).map((audio, index) => (
            <audio key={index} controls src={audio.url} />
          ))}
          {message.attachments?.filter(({ type }) => type.includes('pdf')).map((media, index) => (
            <Button
              icon='fa-duotone fa-file-pdf' label={'Documento ' + (index + 1)} onClick={() => { window.open(media.url, '_blank') }}
              key={index} style={{ marginRight: 10 }}
            />))}
        </Flex>
        <Modal
          style={{ maxWidth: '80vw', maxHeight: '95vh', borderRadius: 10 }}
          contentStyle={{ padding: 0, borderRadius: 10, overflow: 'hidden' }}
          visible={showImg} onHide={() => setShowImg(false)}
        >
          <Button icon='fa-duotone fa-xmark' round onClick={() => setShowImg(false)} style={{ position: 'absolute', top: 10, right: 10 }} />
          {media?.type?.includes('image') && <img src={media?.url} style={{ maxWidth: '100%', maxHeight: '95vh' }} />}
          {media?.type?.includes('audio') &&
            <Flex style={{ minWidth: 500, height: 100 }}>
              <audio controls src={media?.url} style={{ width: '80%' }} ref={(audio) => { audio?.setAttribute('controlsList', 'nodownload') }} />
            </Flex>}
        </Modal>
        {jsonVisualized && !!jsonPayload && (<VisualizeJSONPayload jsonPayload={jsonPayload} />)}
      </div>
    </Flex>
  )
}

const VisualizeJSONPayload = ({ jsonPayload }) => {
  const [openJSON, setOpenJSON] = useState(window?._env_?.ENVIRONMENT === 'development')
  return (
    <>
      {!openJSON && <Button label='Leggi JSON' onClick={() => setOpenJSON(true)} />}
      {openJSON && (
        <>
          <JsonViewer name='Risultato Elaborazione' src={jsonPayload} />
          <Button label='Chiudi' onClick={() => setOpenJSON(false)} />
        </>
      )}
    </>
  )
}

export { ChatWrapper, Writer, ChatMessages, ChatMessage }
