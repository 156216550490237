import React from 'react'
import { Card, Flex } from '../../../../Components'
import { CustomHeader } from '../Components'
import { countryListDropDefault } from '../Functions'
import { FloatInput } from '../TransferField/InputForm'

export const SenderSection = ({
  sender: [sender = { name: '', surname: '', email: '' }, setSender] = [],
  prefix: [prefix, setPrefix],
  cellulare: [cellulare, setCellulare],
  readOnly, checkError, showSuggestions
}) => {
  return (
    <Card customHeader={<CustomHeader title='DATI MITTENTE' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw wrap row>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <FloatInput
            value={[sender?.name, (name) => setSender({ ...sender, name })]}
            id='name' label='Nome' readOnly={readOnly}
          />
        </Flex>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <FloatInput
            value={[sender?.surname, (surname) => setSender({ ...sender, surname })]}
            id='surname' label='Cognome' readOnly={readOnly}
          />
        </Flex>
        <Flex as js style={{ width: '48%', margin: '0px 1%' }}>
          <FloatInput
            value={[sender?.email, (email) => setSender({ ...sender, email })]}
            id='email' label='Email' readOnly={readOnly}
          />
        </Flex>
        <Flex row as js style={{ width: '48%', margin: '0px 1%' }}>
          <Flex as js style={{ width: '40%', marginRight: 10 }}>
            <FloatInput type='dropdown' value={[prefix, setPrefix]} id='prefix' filter label='Prefisso' options={countryListDropDefault} style={{ marginRight: 10 }} readOnly={!!sender?.phone} />
          </Flex>
          <Flex as js style={{ width: '58%' }}>
            <FloatInput value={[cellulare, setCellulare]} id='phone' label='Cellulare' readOnly={!!sender?.phone} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
