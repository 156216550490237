import React from 'react'
import { Flex } from '../../../../Components'
import { Button } from '../../../../Components/Common/Button'
import { useNavigate } from 'react-router-dom'
import { FC, invalidateQuery, useCQuery } from '../../../../Services'
import { useAuth } from '../../../../Provider/AuthProvider'
import { Input } from '../../../../Components/Common/Input'
import { Text } from '../../../../Components/Common/Text'

export const FormHeader = ({ conversationId }) => {
  const { data: users = [] } = useCQuery(['users'])
  const { user: { user = {} } = {} } = useAuth()
  const { data: conversation = {} } = useCQuery(['conversation', conversationId])
  const navigate = useNavigate()

  /**
   * Assegna una conversazione a un utente specifico.
   *
   * @async
   * @function assign
   * @param {Object} params - L'oggetto dei parametri.
   * @param {Object} params.assignDropdown - L'oggetto contenente l'id dell'utente a cui assegnare la conversazione.
   * @throws {Error} Lancia un errore se il servizio non risponde come previsto.
   */
  const assign = async ({ assignDropdown }) => {
    const { id } = assignDropdown
    await FC.service('conversations').patch(conversationId, { userId: id })
    invalidateQuery([['conversations'], ['conversation', conversationId?.toString()]])
  }

  return (
    <Flex je fw row style={{ height: 30 }}>
      <Text value='Assegnato a' style={{ marginRight: 5 }} />
      <Input
        id='assignDropdown'
        dropdown
        style={{ width: 200 }}
        options={[{ id: null, username: 'Nessuno' }, ...users]}
        value={users.find(u => u.id === conversation?.userId) || { id: null, username: 'Nessuno' }}
        onChange={assign}
        optionLabel='username'
      />
      <Button label='Torna indietro' icon='fa-duotone fa-arrow-left' onClick={() => navigate('/operatore/list')} style={{ marginLeft: 10 }} />
      {user?.role === 'admin' &&
        <Button label='Vai a processi' icon='fa-duotone fa-gear' onClick={() => navigate('/conversations/' + conversationId)} style={{ marginLeft: 10 }} />}
    </Flex>
  )
}
