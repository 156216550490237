import React, { useState } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ProtectedRoute, Login, ErrorHandler } from './Pages'
import AuthProvider, { useAuth } from './Provider/AuthProvider'
import { FC } from './Services'
import { Flex, Spinner, Background } from './Components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NewChat } from './Pages/Chat/NewChat'
import { AgencyList } from './Pages/Admin/Agency/List'
import { AgencyEditor } from './Pages/Admin/Agency/Editor'
import { UsersList } from './Pages/Admin/Users/List'
import { UserEditor } from './Pages/Admin/Users/Editor'
import { TravellersList } from './Pages/Admin/Travellers/List'
import { TravellerEditor } from './Pages/Admin/Travellers/Editor'
import { TravellersImport } from './Pages/Admin/Travellers/Import'
import { ProcessesDashboard } from './Pages/Process/Dashboard'
import { ProcessList } from './Pages/Process/List'
import { ProcessStepsVisualizer } from './Pages/Process/Editor'
import { ProcessesErrorStats } from './Pages/Process/ErrorStats'
import { ProcessStepsStats } from './Pages/Process/Stats'
import { ConversationsList } from './Pages/Process/Conversations'
import { ConversationPricing } from './Pages/Process/Pricing'
import { ProcessCompare } from './Pages/Process/Compare'
import { GigiForm } from './Pages/Gigi/GigiForm'
import { GigiList } from './Pages/Gigi/GigiList'
import { AgencyImport } from './Pages/Admin/Agency/Import'
import { AdminCoefficient } from './Pages/AdminCoefficient'

import { cap } from './State'

export const queryClient = new QueryClient()

const LightTheme = React.lazy(() => import('./Themes/Light/Theme'))
const DarkTheme = React.lazy(() => import('./Themes/Dark/Theme'))

const ThemeSelector = ({ children }) => {
  const { themeComponent, themeClassName } = logicLoadTheme()
  return (
    <>
      <React.Suspense fallback={<></>}>
        {themeComponent}
      </React.Suspense>
      <div className={themeClassName}>
        {children}
      </div>
    </>
  )
}

function logicLoadTheme () {
  const [darkMode] = cap.darkMode.useState()
  const { DEFAULT_THEME, SINGLE_THEME } = window._env_
  if (SINGLE_THEME === 'false') {
    return {
      themeComponent: darkMode ? <DarkTheme /> : <LightTheme />,
      themeClassName: `theme-${darkMode ? 'dark' : 'light'}`
    }
    // gestione multipla del tema
  } else {
    // prendo il default con la cartella
    switch (DEFAULT_THEME) {
      case 'light':
        return {
          themeComponent: <LightTheme />,
          themeClassName: 'theme-light'
        }
      case 'dark':
        return {
          themeComponent: <DarkTheme />,
          themeClassName: 'theme-dark'
        }
    }
  }
}

function AppSetup () {
  const { user: storedUser, onLoginJWT } = useAuth()
  const [loading, setLoading] = useState(true)

  useMount(async () => {
    if (FC.authenticated && !storedUser?.accessToken) {
      setLoading(false)
      return true
    }
    storedUser?.accessToken && await onLoginJWT(storedUser?.accessToken)
    setLoading(false)
  })

  return (
    <ThemeSelector>
      <Background whiteBackground>
        <ReactQueryDevtools initialIsOpen={false} />
        {loading ? <Flex fw fh><Spinner /></Flex> : <Outlet />}
      </Background>
    </ThemeSelector>
  )
}

const CheckRoute = ({ children, role = [] }) => {
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  if (role?.length && !role.includes(user?.role)) {
    return <Navigate to='/operatore/list/' />
  }
  return children
}

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorHandler />,
    children: [
      {
        element: <AppSetup />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              { path: '*', element: <CheckRoute> <Navigate to='/operatore/list/' /> </CheckRoute> },
              { path: '/chat/*', element: <CheckRoute role={['admin']}> <Navigate to='/chat/new' /> </CheckRoute> },
              { path: '/chat/new', element: <CheckRoute role={['admin']}> <NewChat /> </CheckRoute> },
              { path: '/conversations/*', element: <CheckRoute role={['admin']}> <Navigate to='/conversations/list' /> </CheckRoute> },
              { path: '/conversations/list', element: <CheckRoute role={['admin']}><ConversationsList /></CheckRoute> },
              { path: '/conversations/:conversationId', element: <CheckRoute role={['admin']}> <ProcessList /> </CheckRoute> },
              { path: '/pricing/:conversationId', element: <CheckRoute role={['admin']}> <ConversationPricing /> </CheckRoute> },
              { path: '/operatore/*', element: <CheckRoute role={['admin', 'operatore']}> <Navigate to='/operatore/list' /> </CheckRoute> },
              { path: '/operatore/list/', element: <CheckRoute role={['admin', 'operatore']}><GigiList noWhatsapp allowCreate /> </CheckRoute> },
              { path: '/operatore/:conversationId', element: <CheckRoute role={['admin', 'operatore']}><GigiForm /> </CheckRoute> },
              { path: '/admin/*', element: <CheckRoute role={['admin']}> <Navigate to='/admin/agency/list' /> </CheckRoute> },
              { path: '/admin/agency/list', element: <CheckRoute role={['admin']}> <AgencyList /> </CheckRoute> },
              { path: '/admin/agency/:agencyId', element: <CheckRoute role={['admin']}> <AgencyEditor /> </CheckRoute> },
              { path: '/admin/agency/import', element: <CheckRoute role={['admin']}> <AgencyImport /> </CheckRoute> },
              { path: '/admin/users/list', element: <CheckRoute role={['admin']}> <UsersList /> </CheckRoute> },
              { path: '/admin/users/:userId', element: <CheckRoute role={['admin']}> <UserEditor /> </CheckRoute> },
              { path: '/admin/travellers/list', element: <CheckRoute role={['admin']}> <TravellersList /> </CheckRoute> },
              { path: '/admin/travellers/import', element: <CheckRoute role={['admin']}> <TravellersImport /> </CheckRoute> },
              { path: '/admin/travellers/:travellerId', element: <CheckRoute role={['admin']}> <TravellerEditor /> </CheckRoute> },
              { path: '/admin/coefficient', element: <CheckRoute role={['admin', 'dynamicTester', 'operatorCommunity']}> <AdminCoefficient /> </CheckRoute> },
              { path: '/process/*', element: <CheckRoute role={['admin']}> <Navigate to='/process/dashboard' /> </CheckRoute> },
              { path: '/process/dashboard', element: <CheckRoute role={['admin']}> <ProcessesDashboard /> </CheckRoute> },
              { path: '/process/errorStats', element: <CheckRoute role={['admin']}> <ProcessesErrorStats /> </CheckRoute> },
              { path: '/process/:processId', element: <CheckRoute role={['admin']}> <ProcessStepsVisualizer /> </CheckRoute> },
              { path: '/process/stats/:processId', element: <CheckRoute role={['admin']}> <ProcessStepsStats /></CheckRoute> },
              { path: '/process/compare/:processId', element: <CheckRoute role={['admin']}> <ProcessCompare /></CheckRoute> }
            ]
          },
          { path: '/login', element: <Login /> }
        ]
      }
    ]
  }
])
function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
export default App
