import { getCorrectPhone } from '../GigiForm/Functions'

export const conversationsStatus = {
  open: 'In corso',
  ended: 'Concluso',
  error: 'Errore'
}

export const STATE_ENUM = [
  { label: 'Creato', value: 1, code: 'INITIAL', color: 'blue' },
  { label: 'In esecuzione', value: 2, code: 'RUNNING', color: 'violet' },
  { label: 'Terminato', value: 3, code: 'ENDED', color: 'green' },
  { label: 'In pausa', value: 4, code: 'PAUSE', color: 'azure' },
  { label: 'Errore', value: 5, code: 'ERROR', color: 'red' },
  { label: 'In esecuzione', value: 7, code: 'READYTORESTART', color: 'orange' }
]

export const getStateValues = () => {
  const obj = {}
  STATE_ENUM.forEach((e) => { obj[e.code] = e.value })
  return obj
}

export const renderPhone = (row) => {
  const { traveller: { phone = '' } } = row || {}
  if (phone) {
    const formattedPhone = getCorrectPhone(phone)
    const prefix = formattedPhone.split('.')[0]
    const phoneNumber = formattedPhone.split('.')[1]
    return '+(' + prefix + ') ' + phoneNumber
  } else {
    return 'Non definito'
  }
}

export const renderClient = (row) => {
  const { traveller: { client } } = row || {}
  const { ragSociale = '' } = client || {}
  return ragSociale || 'Non definito'
}

export const renderEnvironment = (row) => {
  const { traveller: { client } } = row || {}
  const { environment: { agencyName = '' } = {} } = client || {}
  return agencyName || 'Non definito'
}

export const formatDateHour = (createdAt) => {
  const isToday = createdAt.toDateString() === new Date().toDateString()
  const hour = createdAt.getHours() + ':' + ('0' + createdAt.getMinutes()).slice(-2)
  const itDays = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
  const itMonths = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']

  const dayName = itDays[createdAt.getDay()]
  const day = createdAt.getDate()
  const monthName = itMonths[createdAt.getMonth()]

  const formattedDate = dayName + ' ' + day + ' ' + monthName
  if (createdAt) return isToday ? hour : formattedDate + ' ' + hour
  return ''
}
