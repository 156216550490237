export default [
  {
    id: 'plane',
    name: 'Aereo',
    icon: 'plane',
    filters: [
      { name: 'Prezzo', code: 'p' },
      { name: 'Orario', code: 'o' },
      { name: 'Durata', code: 'd' },
      { name: 'Best buy', code: 'bb' }
    ]
  },
  {
    id: 'hotel',
    name: 'Hotel',
    icon: 'hotel',
    filters: [
      { name: 'Prezzo', code: 'p' },
      { name: 'Distanza', code: 'd' },
      { name: 'Best buy', code: 'bb' }
    ]
  }
]
