import { FC } from '../../../../Services/FeathersClient'

/**
 * Restituisce lo stato della differenza per un dato elemento basato su un identificativo e una condizione di conteggio.
 *
 * @param {Object} param0 - Oggetto con la proprietà id dell'elemento da cercare.
 * @param {number} param0.id - L'ID dell'elemento per il quale si vuole ottenere lo stato della differenza.
 * @param {Array} diff - Array di elementi che contengono le proprietà delle differenze.
 * @param {boolean} [count=false] - Opzionale, se impostato a true, verifica anche la corrispondenza del conteggio.
 * @returns {string} Lo stato della differenza dell'elemento corrispondente o una stringa vuota se non trovato.
 */
export const getDiffStatus = ({ id }, diff, count = false) =>
  diff?.find((el) => el?.id === id && (count === false || count === el.count))?.diffStatus || ''

export const getRoomsCount = (json) => json?.websalesAI?.paxDetails?.length || 0

export const getChildrenCount = (json, i) => parseInt(json?.websalesAI?.paxDetails?.[i]?.childrenAge?.length) || 0

export const getTotalChildrenCount = (json) => {
  let total = 0
  for (const pax of json?.websalesAI?.paxDetails || []) total += parseInt(pax?.childrenAge?.length) || 0
  return total
}

export const removeRoom = (json, index) => {
  const newJson = JSON.parse(JSON.stringify(json))
  newJson.websalesAI.paxDetails.splice(index, 1)
  return newJson
}

export const addRoom = async (json) => {
  const newJson = JSON.parse(JSON.stringify(json))
  const jsonToPush = {}
  const formFields = await FC.service('info').get('formFields')
  for (const field of formFields.filter((field) => field.section === 'rooms')) jsonToPush[field.id] = 0
  jsonToPush.childrenAge = []
  if (!newJson?.websalesAI?.paxDetails?.length) newJson.websalesAI.paxDetails = []
  newJson.websalesAI.paxDetails.push(jsonToPush)
  return newJson
}

export const addChildren = async (json, i) => {
  const newJson = JSON.parse(JSON.stringify(json))
  const jsonToPush = {}
  const formFields = await FC.service('info').get('formFields')
  for (const field of formFields.filter((field) => field.section === 'childrenAge')) jsonToPush[field.id] = 1
  if (!newJson?.websalesAI?.paxDetails?.[i]?.childrenAge?.length) newJson.websalesAI.paxDetails[i].childrenAge = []
  newJson.websalesAI.paxDetails[i].childrenAge.push(jsonToPush)
  newJson.websalesAI.paxDetails[i].children = parseInt(newJson.websalesAI.paxDetails[i].childrenAge.length) || 0
  return newJson
}

export const removeChild = (json, i, p) => {
  const newJson = JSON.parse(JSON.stringify(json))
  newJson.websalesAI.paxDetails[i].childrenAge.splice(p, 1)
  newJson.websalesAI.paxDetails[i].children = parseInt(newJson.websalesAI.paxDetails[i].childrenAge.length) || 0
  return newJson
}
