import { motion } from 'framer-motion'
import React, { useState, useEffect } from 'react'
import { Text } from './Text'
import { Flex } from './Flex'
import FadeIn from './FadeIn'
import { Button } from './Button'

const transition = { default: { duration: 0.4 } }
const CardContainer = ({ children, ...props }) => (
  <motion.div
    {...props} variants={{
      open: { height: 'auto', opacity: 1, marginTop: 10, transition },
      closed: { height: 0, opacity: 0, marginTop: 0, transition }
    }}
  >
    {children}
  </motion.div>
)

const ChevronContainer = ({ children, ...props }) => (
  <motion.div
    {...props} variants={{
      open: { rotate: 0, transition },
      closed: { rotate: 180, transition }
    }}
  >
    {children}
  </motion.div>
)

export const Card = ({ fadeIn, collapsable, title, titleCollapsed, titleStyle, collapsed, children, style, customHeader, onExpand, onClick = () => { } }) => {
  const [state, setState] = useState('closed')
  const isCollapsed = state === 'closed'

  const toggleCollapse = (newState) => {
    const applyNewState = newState || (isCollapsed ? 'open' : 'closed')
    setState(applyNewState)
    applyNewState === 'open' && onExpand && onExpand()
  }

  useEffect(() => { collapsable && setState(collapsed ? 'closed' : 'open') }, [collapsable, collapsed])

  const cardStyle = {
    padding: '20px 50px',
    borderRadius: 10,
    flexFlow: 'column wrap',
    columnGap: 10,
    ...(collapsable ? { position: 'relative' } : {}),
    ...(collapsable && isCollapsed && !customHeader ? { cursor: 'pointer' } : {}),
    ...style,
    width: '100%'
  }

  return (
    <FadeIn enabled={fadeIn} style={{ ...(style ? { width: style.width, height: style.height } : {}) }}>
      <div className='card' style={cardStyle} {...(isCollapsed && !customHeader) ? { onClick: () => toggleCollapse('open') } : {}}>
        {!customHeader && !title
          ? null
          : (
            <Flex row jb fw>
              <Flex row js>
                {title ? <Text title bold value={titleCollapsed ? (isCollapsed ? titleCollapsed : title?.toUpperCase()) : title?.toUpperCase()} style={{ ...titleStyle, marginRight: 20, marginBottom: 10 }} size={20} /> : null}
              </Flex>
              {customHeader}
              <ChevronContainer initial='closed' animate={state} style={{ cursor: 'pointer', visibility: !collapsable && 'hidden' }} onClick={() => (customHeader || !isCollapsed) && toggleCollapse()}>
                <Button round icon='fa-duotone fa-down' />
              </ChevronContainer>
            </Flex>)}
        {!collapsable
          ? (
            <div onClick={onClick}>
              {children}
            </div>)
          : (
            <CardContainer initial='closed' style={{ overflow: 'hidden' }} animate={state}>
              {children}
            </CardContainer>)}
      </div>
    </FadeIn>
  )
}
