import { Card, Flex } from '../../../../Components'
import { CustomHeader } from '../Components'
import { useElementsByService } from '../Functions'
import { renderElements } from '../RenderFunctions'

export const MainDataSection = ({
  state: [state, setState] = [], readOnly, diff
}) => {
  const { mainData = {} } = useElementsByService()
  const stateProp = [state, setState]

  return (
    <Card customHeader={<CustomHeader title='DATI RICHIESTA' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw wrap row js>
        {renderElements(mainData, stateProp, readOnly)}
      </Flex>
    </Card>
  )
}
