import React, { useEffect, useState } from 'react'
import { Card, ChatMessages, ChatWrapper, Flex, Writer } from '../../Components'
import { FC, useCQuery } from '../../Services'
import { useAuth } from '../../Provider/AuthProvider'
import SettingsParameterTest from './Components/SettingsParameterTest'
import { getSenderLabel } from '../Gigi/GigiForm/Functions'

export function NewChat () {
  const [conversationId, setConversationId] = useState()
  const { data: conversation = {} } = useCQuery(['conversation', conversationId])
  const [selectedFormalita, setSelectedFormalita] = useState(75)
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { id: userId } = userObj
  const [messages, setMessages] = useState([])
  const [selectedEmail, setSelectedEmail] = useState('mranocchia@siapcn.it')
  const [selectedProcess, setSelectedProcess] = useState('messageWebsalesAI')
  // const { data: travellers = [] } = useCQuery('travellers')

  useEffect(() => {
    if (conversation?.messages?.length !== messages?.length) {
      const travellerPhone = conversation?.traveller?.phone
      setMessages(conversation?.messages
        ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        ?.map(({ message: messageText, sender, createdAt, attachments, userId }) => ({
          message: messageText,
          sender,
          user: getSenderLabel(sender, travellerPhone),
          createdAt: new Date(createdAt),
          attachments
        })) || [])
    }
  }, [conversation?.messages])

  const sendMessage = async (message, attachments) => {
    setMessages([...messages, { message, sender: 'traveller', user: getSenderLabel('traveller', conversation?.traveller?.phone), createdAt: new Date(), attachments }])
    const res = await FC.service('public').create({
      type: 'testUnit',
      unit: selectedProcess,
      attachments,
      user: userId,
      tomcatCode: user?.user?.environment?.tomcatCode,
      operatorEmail: selectedEmail,
      userPhone: '393312207382',
      userEmail: 'mranocchia@siapcn.it',
      userInput: message,
      levelFormalita: selectedFormalita || 75,
      isDebug: false,
      agencyPhone: '393456789012',
      agencyEmail: 'wondertravel@siapcn.it'
    })
    res?.conversationData?.conversationId !== conversationId && setConversationId(res?.conversationData?.conversationId)
    return true
  }

  return (
    <Flex fw fh js>
      <Card style={{ width: '90%' }}>
        <Flex fw row>
          <SettingsParameterTest
            selectedFormalita={[selectedFormalita, setSelectedFormalita]}
            selectedEmail={[selectedEmail, setSelectedEmail]}
            selectedProcess={[selectedProcess, setSelectedProcess]}
          />
        </Flex>
        <Flex fw style={{ marginTop: 30 }}>
          <Flex fw row>
            <ChatWrapper>
              <ChatMessages messages={messages} jsonVisualized={false} />
              <Writer sendMessage={sendMessage} />
            </ChatWrapper>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  )
}
