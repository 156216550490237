import { Card, Flex } from '../../../../Components'
import { CustomHeader, SingleServiceContainer } from '../Components'
import { Text } from '../../../../Components/Common/Text'
import { addRoom, getRoomsCount, getChildrenCount, removeRoom, addChildren, removeChild } from './Functions'
import { Button } from '../../../../Components/Common/Button'
import { useElementsByService } from '../Functions'
import { renderElements } from '../RenderFunctions'

export const Rooms = ({ state: [state, setState] = [], readOnly, client: [client] = [] }) => {
  const stateProp = [state, setState]
  const { rooms = {}, childrenAge = {} } = useElementsByService()

  return (
    <Card customHeader={<CustomHeader title='CAMERE' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw>
        <Flex fw row jb>
          {!getRoomsCount(state) ? <Text value='Nessuna camera inserito' style={{ fontFamily: 'Lexend', width: 300 }} /> : <div />}
          {!readOnly && <Button icon='fa-duotone fa-plus' label='Aggiungi camera' onClick={async () => setState(await addRoom(state))} />}
        </Flex>
        {Array(getRoomsCount(state)).fill().map((_, i) => (
          <SingleServiceContainer collapsable ind={i} key={i} onRemove={() => setState(removeRoom(state, i))} readOnly={readOnly} label='Camera' preLabel='questa'>
            {renderElements(rooms, stateProp, readOnly, i)}
            <Flex fw row jb style={{ marginTop: 10, padding: 10 }}>
              {!!getChildrenCount(state, i) && <Text value='Età bambini' bold upCase />}
              {!readOnly && <Button icon='fa-duotone fa-plus' label='Aggiungi bambino' onClick={async () => setState(await addChildren(state, i))} />}
            </Flex>
            {Array(getChildrenCount(state, i)).fill().map((_, p) => (
              <SingleServiceContainer key={p} readOnly={readOnly} label='Bambino' onRemove={() => setState(removeChild(state, i, p))} preLabel='questo'>
                {renderElements(childrenAge, stateProp, readOnly, i, p)}
              </SingleServiceContainer>
            ))}
          </SingleServiceContainer>
        ))}
      </Flex>
    </Card>
  )
}
