import { useEffect, useState } from 'react'
import { readKey, writeKey } from '../Functions'

export const useTransferField = ([json, setJson], location, count, count2, defaultValue) => {
  const [value, setValue] = useState(readKey(json, location, count, count2) || defaultValue) // lo inizializzo subito senza aspettare il primo render perché i campi di input sono rossi per un attimo

  const arrDependencies = [
    location?.includes('.children') ? json?.websalesAI?.paxDetails?.[count]?.children : null,
    location?.includes('.adults') ? json?.websalesAI?.paxDetails?.[count]?.adults : null,
    location?.includes('.checkIn') ? json?.websalesAI?.checkIn : null,
    location?.includes('.checkOut') ? json?.websalesAI?.checkOut : null,
    location?.includes('.arrival') ? json?.websalesAI?.arrival : null,
    location?.includes('.priorityType') ? json?.websalesAI?.priorityType : null,
    location?.includes('.packageType') ? json?.websalesAI?.packageType : null,
    location?.includes('.departureAirport') ? json?.websalesAI?.departureAirport : null
  ].filter(i => i !== null)

  const customSet = (value = '') => {
    setValue(value)
    const newJson = writeKey(json, location, count, count2, value)
    Object.keys(newJson)?.length && setJson(newJson)
  }

  useEffect(() => {
    const newValue = readKey(json, location, count, count2)
    customSet(newValue)
  }, [...arrDependencies])

  return [value, customSet]
}
